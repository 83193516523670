<template>
  <div id="app">
    <Header/>
    <router-view path="$router.key"/>
    <Footer/>
  </div>
</template>
<script>
import './assets/styles/global.css';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
export default{
  name: 'App',
  components:{
    Header,
    Footer,
  },
  data(){
        return{

        }
    }

}
</script>
<style>

</style>
